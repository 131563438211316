import React, { useEffect, useState } from "react";
import Tourcard from "./Tourcard";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import GridLoader from "../Loaders/GridLoader";

const Daytours = () => {
  const [dayTour, setDayTour] = useState([]);
  const [cardImage, setCardImage] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}daytour/get`,
    })
      .then((res) => {
        let response = res?.data?.data?.daytours?.data;
        setDayTour(response);
        console.log("here we print reaponse", res?.data?.data?.daytours?.data);
        console.log("imageurl path", res?.data?.data?.main_image_base_url);
        setCardImage(res?.data?.data?.main_image_base_url);
        setLoading(false);
      })
      .catch((err) => {
        console.log("this is error", err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="section med-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="sub-section">
                <h5 className="title m-0">Day-tours</h5>
              </div>
            </div>

            {loading ? (
              <GridLoader item={4} />
            ) : (
              <>
                <div className="col-lg-6 col-md-12 more-btn">
                  <Link to="/tour/day-tour" class="text-decoration-none">
                    <button type="button" class="btn">
                      More <i class="fa-solid fa-arrow-right"></i>
                    </button>
                  </Link>
                </div>
                {dayTour?.slice(0, 4)?.map((get, keys) => (
                    <div className="col-lg-3 col-md-6">
                      <Tourcard type="day" data={get} imageurl={cardImage} />
                    </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Daytours;
