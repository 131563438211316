import { useNavigate } from "react-router-dom";
import "./App.css";
import Footer from "./Component/Footer";
import Navbar from "./Component/Navbar";
import Allroutes from "./Routers/Allroutes";

function App() {
  const navigateMe = (id) => {
    setTimeout(() => {
      // const myId = window.location.hash.slice(1);
      const elem = document.getElementById(id);
      if (elem) {
        elem.scrollIntoView();
      }
    }, 200);
  };
  return (
    <>
      <Navbar navigateMe={navigateMe} />
      <Allroutes />
      <Footer />
    </>
  );
}

export default App;
