import React from "react";
import Regexcludacc from "../Component/Reg_peg_cmp/Regexcludacc";
import Regincludacc from "../Component/Reg_peg_cmp/Regincludacc";
import { Link, useNavigate } from "react-router-dom";

const Registrationpage = () => {
    return (
        <>
            <div className="pagesection">
                    <Regincludacc />
                    <Regexcludacc />
            </div>

        </>
    )
}

export default Registrationpage