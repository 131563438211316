import React from "react";
import parse from "html-react-parser";
function Accordion({ data }) {
  const handleIcon = (e, id) => {
    e.preventDefault();

    let icon = document.getElementById("icon-" + id);
    if (icon.classList.contains("fa-plus")) {
      handleRemainingAccordion();

      icon.classList.remove("fa-plus");
      icon.classList.add("fa-minus");

      return;
    } else if (icon.classList.contains("fa-minus")) {
      handleRemainingAccordion();

      icon.classList.remove("fa-minus");
      icon.classList.add("fa-plus");

      return;
      // If the icon is already active, return
    }
  };

  const handleRemainingAccordion = () => {
    let allMinus = document.getElementsByClassName("fas");

    for (let i = 0; i < allMinus.length; i++) {
      if (allMinus[i].classList.contains("fa-minus")) {
        allMinus[i].classList.remove("fa-minus");
        allMinus[i].classList.add("fa-plus");
      }
    }
  };

  return (
    <div className="bg-gray" id="accordion-style-1">
      {/* <div className="container"> */}
      <section>
        <div className="row">
          <div className="col-12">
            <div className="accordion" id="accordionExample">
              {data?.map((item, id) => (
                <div class="accordion-item">
                  <h2
                    class="accordion-header"
                    id={`panelsStayOpen-heading${id}`}
                  >
                    <button
                      onClick={(e) => handleIcon(e, id)}
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#panelsStayOpen-collapse${id}`}
                      aria-expanded="false"
                      aria-controls={`panelsStayOpen-collapse${id}`}
                    >
                      <i class="fas fa-plus" id={`icon-${id}`}></i> &nbsp;
                      &nbsp;
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`panelsStayOpen-collapse${id}`}
                    class="accordion-collapse collapse"
                    aria-labelledby={`panelsStayOpen-heading${id}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      {parse(item.description || "")}
                    </div>
                  </div>
                </div>

                // <div className="card">
                //   <div className="card-header" id={`${item.title}-${id}`}>
                //     <h5 className="mb-0">
                //       <button
                //         className="btn btn-link collapsed btn-block text-left"
                //         type="button"
                //         data-toggle="collapse"
                //         data-target={`#${item.title}`}
                //         aria-expanded="false"
                //         aria-controls={`${item.title}`}
                //       >
                //         <i className="fa fa-angle-double-right mr-3" />
                //         {item.title}
                //       </button>
                //     </h5>
                //   </div>
                //   <div
                //     id={`${item.title}`}
                //     className="collapse fade"
                //     aria-labelledby={`${item.title}-${id}`}
                //     data-parent="#accordionExample"
                //   >
                //     <div className="card-body">
                //       {parse(item.description || "")}
                //     </div>
                //   </div>
                // </div>
              ))}
              {/* <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <i className="fa fa-angle-double-right mr-3" />
                        How to pay amazon.
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show fade"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid. 3 wolf moon officia
                      aute, non cupidatat skateboard dolor brunch. Food truck
                      quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                      tempor, sunt aliqua put a bird on it squid single-origin
                      coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                      helvetica, craft beer labore wes anderson cred nesciunt
                      sapiente ea proident. Ad vegan excepteur butcher vice
                      lomo. Leggings occaecat craft beer farm-to-table, raw
                      denim aesthetic synth nesciunt you probably haven't heard
                      of them accusamus labore sustainable VHS.
                      <a
                        href="https://www.fiverr.com/sunlimetech/fix-your-bootstrap-html-and-css3-issues"
                        className="ml-3"
                        target="_blank"
                      >
                        <strong>
                          View More designs{" "}
                          <i className="fa fa-angle-double-right" />
                        </strong>
                      </a>
                    </div>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* </div> */}
    </div>
  );
}

export default Accordion;
