import React from "react";

import { Link } from "react-router-dom";
import { AddToCart } from "../helpers/CartHelper";
const Pretourpagecrd = ({ data, imageurl, type }) => {
  const isSoldOut = data.status === "0";
  return (
    <>
      <Link to={type === "pre" ? `/tour/pre-tour/pretourdescription/${data?.id}` : `/tour/day-tour/daytourdescription/${data?.id}`} class="text-decoration-none">
        <div class="card border-0 mb-4">
          <div className="pre-tour-img crd-img">
            <img src={`${imageurl}/${data?.main_image}`} class="pretour/daytour images" alt="..." />
          </div>
          <div class="card-body px-0 pre-tour-txt">
            <h6 class="card-title text-decoration-none">{data.title}</h6>
            <h5 class="card-title sub-title">{data.subtitle}</h5>
            <div className=" d-flex justify-content-between pre-sub-txt">
              <p>$ {data.price}</p>
              {isSoldOut ? (
                <button type="button" className="btn" disabled>
                  Sold Out
                </button>
              ) : (
                <button
                  type="button"
                  class="btn"
                  onClick={(e) => {
                    e.preventDefault();
                    AddToCart({
                      image: `${imageurl}/${data?.main_image}`,
                      title: data?.title,
                      price: data?.price,
                      type: "tour",
                      id: data?.id,
                    });
                  }}>
                  Add to cart
                </button>
              )}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Pretourpagecrd;
