import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  fireErrorModal,
  fireErrorToaster,
  fireSuccessModal,
} from "../helpers/alertProvider";
import Captcha from "../providers/Captcha";

const initialState = {
  name: "",
  phone: "",
  email: "",
  description: "",
};

const Contactus = () => {
  const [userContactData, setUserContactData] = useState(initialState);
  const [error, setError] = useState("");
  const [captchaStatus, setCaptchaStaus] = useState(false);
  const recaptchaRef = React.useRef();

  let formdata = new FormData();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (captchaStatus == false) {
      fireErrorToaster("Please fill the captcha");
      return;
    }

    formdata.append("name", userContactData.name);
    formdata.append("phone", userContactData.phone);
    formdata.append("email", userContactData.email);
    formdata.append("description", userContactData.description);
    console.log("form data are : ", formdata);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}contactus/store`,
      data: formdata,
    })
      .then((res) => {
        console.log(res);
        fireSuccessModal("Your form has been submitted successfully");
        setUserContactData(initialState);
        recaptchaRef.current.reset();
      })
      .catch((err) => {
        console.log("this is error", err);
        fireErrorModal(
          err.response.data.message || err.message || "Failed to submit form"
        );
      });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setUserContactData({ ...userContactData, [name]: value });
    if (!userContactData.name) {
      setError("Name is required");
    } else {
      setError("");
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userContactData.email)) {
      setError("Valid email required");
    } else {
      setError("");
    }
    if (userContactData.phone === "") {
      setError("Please enter your phone number ");
    } else {
      setError("");
    }
  };

  return (
    <>
      <div className="section med-sec">
        <div className="container-fluid" id="contactus">
          <div className="row">
            <div className="col-lg-6 contect-col">
              <div className="sub-section">
                <h5 className="title m-0">Contact Us</h5>
                <p className="sub-title">
                  You can send us a message and we will get back to you.
                </p>
              </div>
              <div className="row">
                <form>
                  <div className="col-lg-12 med-oly conct-sec">
                    <label
                      for="exampleInputPassword1"
                      class="form-label lbl med-oly"
                    >
                      Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control input-fld-sec"
                      id="exampleFormControlInput1"
                      placeholder="Full Name"
                      name="name"
                      value={userContactData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-lg-12 med-oly mt-4 conct-sec">
                    <label
                      for="exampleInputPassword1"
                      class="form-label lbl med-oly"
                    >
                      Phone <span>*</span>
                    </label>
                    <input
                      type="tel"
                      class="form-control input-fld-sec"
                      id="exampleFormControlInput1"
                      placeholder="Phone number"
                      name="phone"
                      value={userContactData.phone}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) event.preventDefault();
                      }}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-lg-12 med-oly mt-4 conct-sec">
                    <label for="exampleInputPassword1" class="form-label">
                      Email <span>*</span>
                    </label>
                    <input
                      type="email"
                      class="form-control input-fld-sec"
                      id="exampleFormControlInput1"
                      placeholder="Enter your email"
                      name="email"
                      value={userContactData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-lg-12 med-oly mt-4 conct-sec">
                    <label for="exampleInputPassword1" class="form-label">
                      Comment or Message
                    </label>
                    <input
                      type="text"
                      class="form-control input-fld-sec"
                      id="exampleFormControlInput1"
                      placeholder="Write your message"
                      name="description"
                      value={userContactData.description}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-12 mt-4">
                    <Captcha
                      setCaptchaStaus={setCaptchaStaus}
                      recaptchaRef={recaptchaRef}
                    />
                  </div>

                  {/* {error && <div style={{ color: "red" }}>{error}</div>} */}
                  <div
                    className="card-btn  mt-5"
                    onClick={(e) => handleSubmit(e)}
                  >
                    <a href="#" class="btn" type="submit">
                      Submit
                    </a>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 pt-4 contact-cards">
              <div className="card bg-dark text-white contact my-3">
                <div className="contact-left">
                  <h5 className="card-title">RTIHYM Convener - Mayank Kedia</h5>
                  <p className="card-text">
                    For all general and hotel-related queries
                  </p>
                  <p>
                    {/* <a href="mailto:johndoe@gmail.com" id="email">
                    johndoe@gmail.com
                  </a>
                  | */}
                    <a href="https://wa.me/+9779855022363" id="email">
                      <i class="fa-brands fa-whatsapp"></i> +977-9855022363
                    </a>
                  </p>
                </div>

                <div
                  className="contact-right"
                  style={{
                    backgroundImage: "url('images/mayank.jpeg')",
                  }}
                ></div>
              </div>

              {/* <div className="card my-3  bg-dark text-white">
                <h5 className="card-title">RTIHYM CONVENOR TR. SUMIT MITTAL</h5>
                <p className="card-text">
                  For all general and hotel related queries
                </p>
                <p>
                 
                  <a href="https://wa.me/+9779851029321" id="email">
                    +977-9851029321
                  </a>
                </p>
              </div> */}

              <div className="card bg-dark text-white contact my-3">
                <div className="contact-left">
                  <h5 className="card-title">
                    RTIHYM Co-Convener - Sumit Mittal
                  </h5>
                  <p className="card-text">
                    For all general and hotel-related queries
                  </p>
                  <p>
                    {/* <a href="mailto:johndoe@gmail.com" id="email">
                    johndoe@gmail.com
                  </a>
                  | */}
                    <a href="https://wa.me/+9779801029321" id="email">
                      <i class="fa-brands fa-whatsapp"></i> +977-9801029321
                    </a>
                  </p>
                </div>

                <div
                  className="contact-right"
                  style={{
                    backgroundImage: "url('images/sumit.jpg')",
                  }}
                ></div>
              </div>

              <div className="card bg-dark text-white contact my-3">
                <div className="contact-left">
                  <h5 className="card-title">
                    RTIHYM Registrations - Mohit Kedia
                  </h5>
                  <p className="card-text">
                    For all registration-related queries
                  </p>
                  <p>
                    {/* <a href="mailto:johndoe@gmail.com" id="email">
                    johndoe@gmail.com
                  </a>
                  | */}
                    <a href="https://wa.me/+9779802009900" id="email">
                      <i class="fa-brands fa-whatsapp"></i> +977-9802009900
                    </a>
                  </p>
                </div>

                <div
                  className="contact-right"
                  style={{
                    backgroundImage: "url('images/mohit.jpg')",
                  }}
                ></div>
              </div>

              <div className="card bg-dark text-white contact my-3">
                <div className="contact-left">
                  <h5 className="card-title">RTIHYM Tours - Ankit Agarwal</h5>
                  <p className="card-text">For all tours related queries</p>
                  <p>
                    {/* <a href="mailto:johndoe@gmail.com" id="email">
                    johndoe@gmail.com
                  </a>
                  | */}
                    <a href="https://wa.me/+9779851107089" id="email">
                      <i class="fa-brands fa-whatsapp"></i> +977-9851107089
                    </a>
                  </p>
                </div>

                <div
                  className="contact-right"
                  style={{
                    backgroundImage: "url('images/ankit.jpg')",
                  }}
                ></div>
              </div>

              <div className="card bg-dark text-white contact my-3">
                <div className="contact-left">
                  <h5 className="card-title">
                    RTIHYM Transportation
                    <br></br>& Logistics - Nishant Sawarthia
                  </h5>
                  <p className="card-text">For all logistics-related queries</p>
                  <p>
                    {/* <a href="mailto:johndoe@gmail.com" id="email">
                    johndoe@gmail.com
                  </a>
                  | */}
                    <a href="https://wa.me/+9779823035889" id="email">
                      <i class="fa-brands fa-whatsapp"></i> +977-9823035889
                    </a>
                  </p>
                </div>

                <div
                  className="contact-right"
                  style={{
                    backgroundImage: "url('images/nishant.jpg')",
                  }}
                ></div>
              </div>

              {/* <div className="cnct-us-img">
                <img src="/images/buddha.jpg" alt="contanct us image"></img>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
