import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
function Captcha({ setCaptchaStaus, recaptchaRef }) {
  const handleChange = () => {
    setCaptchaStaus(true);
  };

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey="6LfLSMckAAAAALX1wlj3lG2-sNsX6Y1iURl91g7B"
      onChange={handleChange}
    />
  );
}

export default Captcha;
