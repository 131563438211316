import React, { useEffect, useState } from "react";
import Accomodationcrd from "./Accomadationcrd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import GridLoader from "../../Loaders/GridLoader";

const Accomodation = () => {
  const [accomodation, setAccomodation] = useState([]);
  const [mainImageURL, setMainImageURL] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}accommodation/get`,
    })
      .then((res) => {
        console.log(res.data.data);
        let response = res?.data?.data?.accommodations?.data;
        setMainImageURL(res.data.data?.accommodation_main_image_base_url);
        setAccomodation(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log("this is error", err);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div className="section med-sec">
        <div className="container" id="accomodation">
          <div className="sub-section text-center">
            <h5 className="title m-0">Accomodation</h5>
          </div>

          {loading ? (
            <GridLoader item={2} />
          ) : (
            <div className="row">
              {accomodation.map((get, keys) => (
                <>
                  <div className="col">
                    <Link
                      to="/accomodation/accomodationdescription"
                      class="text-decoration-none"
                    >
                      <Accomodationcrd data={get} imageURL={mainImageURL} />
                    </Link>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Accomodation;
