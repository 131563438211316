import React, { useEffect } from "react";
import Accomodation from "../Component/Accomodation/Accomodation";
import Banner from "../Component/Banner";
import Contactus from "../Component/Contactus";
import Daytours from "../Component/Daytours";
import Pretours from "../Component/Pretours";
import Schedule from "../Component/Schedule";
import Youtubesec from "../Component/Youtubesec";

const Homepage = () => {
  return (
    <>
      <Banner />
      <Youtubesec />
      <Schedule />
      <Pretours />
      <Daytours />
      <Accomodation />
      <Contactus />
    </>
  );
};

export default Homepage;
