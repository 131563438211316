import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Counter from "../Component/Counter";
import parse from "html-react-parser";
import { AddToCart } from "../helpers/CartHelper";
import { DetailLoader } from "../Loaders/DetailPageLoader";
import Accordion from "../Component/Accordion";
import { SRLWrapper } from "simple-react-lightbox";

const Tourdescriptionpage = ({ type }) => {
  const params = useParams();

  const [detail, setDetail] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const [otherImageUrl, setOtherImageUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const isSoldOut = detail.status === "0";

  useEffect(() => {
    if (params.id) {
      if (type === "day") {
        axios({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}daytour/get/${params.id}`,
        })
          .then((res) => {
            setImageUrl(res.data.data.main_image_base_url);
            setFileUrl(res.data.data.file_base_url);
            setOtherImageUrl(res.data.data.image_base_url);
            setDetail(res.data.data.daytours);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e.message);
            setLoading(false);
          });
        // fetch tour detail here
      }

      if (type === "pre") {
        axios({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}pretour/get/${params.id}`,
        })
          .then((res) => {
            //   console.log("Response is", res.data);
            setImageUrl(res.data.data.main_image_base_url);
            setOtherImageUrl(res.data.data.image_base_url);
            setFileUrl(res.data.data.file_base_url);

            setDetail(res.data.data.pretour);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e.message);
            setLoading(false);
          });
        // fetch preDetail Here
      }
    }
  }, [params]);

  if (loading) {
    return (
      <div className="container my-5">
        {" "}
        <DetailLoader />
      </div>
    );
  }

  return (
    <>
      <div className="pagesection">
        <div className="container">
          <div className="tour-des-pga">
            <div className="des-img">
              <img
                src={`${imageUrl}/${detail?.main_image}`}
                // src="/images/description.jpg"
                class="card-img-top"
                alt="card images"
              />
            </div>
            <div className="overlay-des">
              <div className=" all-des-ele ">
                <div className="dex-txt-are">
                  <h6>{detail?.title}</h6>
                  <p>{detail?.subtitle} </p>
                </div>
                <div className="price">
                  <p>Price</p>
                  <h6>$ {detail?.price}</h6>
                </div>
                <div className="add-cart-btn ">
                  {isSoldOut ? (
                    <button type="button" className="btn btn-primary" disabled>
                      Sold Out
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        AddToCart({
                          image: `${imageUrl}/${detail?.main_image}`,
                          title: detail?.title,
                          price: detail?.price,
                          type: "tour",
                          id: detail?.id,
                        });
                      }}>
                      {" "}
                      Add to cart
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <SRLWrapper
            options={{
              caption: {
                showCaption: false,
              },
            }}>
            <div className="row my-4 justify-content-center gallery">
              {detail?.tour_images?.map((item) => (
                <div className="col-lg-2 col-md-4 sub-dec-img">
                  <img src={otherImageUrl + "/" + item.image} alt="pictures"></img>
                </div>
              ))}

              {/* <div className="col-lg-2 col-md-4 sub-dec-img ">
                <img src="/images/Pond.jpg" alt="pictures"></img>
              </div>
              <div className="col-lg-2 col-md-4 sub-dec-img">
                <img src="/images/Pond.jpg" alt="pictures"></img>
              </div>
              <div className="col-lg-2 col-md-4 sub-dec-img">
                <img src="/images/Pond.jpg" alt="pictures"></img>
              </div>
              <div className="col-lg-2 col-md-4 sub-dec-img">
                <img src="/images/Pond.jpg" alt="pictures"></img>
              </div>
              <div className="col-lg-2 col-md-4 sub-dec-img">
                <img src="/images/Pond.jpg" alt="pictures"></img>
              </div> */}
            </div>
          </SRLWrapper>

          <div className="tour-des-txt my-4">
            {/* <h6>Tour Description</h6> */}
            {parse(detail?.short_description || "")}
          </div>

          <div className="my-4">
            <Accordion data={detail?.descriptions} />
          </div>

          {detail?.video_link && (
            <div className="my-4">
              <iframe
                width="100%"
                height="550px"
                src={detail.video_link}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; 
                         encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          )}

          {detail?.file && (
            <a href={fileUrl + "/" + detail.file} className="btn btn-sm download-link" target="_blank">
              Disclaimer Form
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default Tourdescriptionpage;
