import React from "react";
import CountDown from "./Countdowntimer/Countdown";
import BannerImage from "../assets/images/banner.jpg";

const Banner = () => {
  return (
    <>
      <div className="">
        <div className="banner">
          <div className="banner-img">
            <img src={BannerImage} alt="banner image"></img>
          </div>
          <div className="d-flex justify-content-center">
            <div className="text-ovr-img">
              <div className="logoimg mb-4">
                <img src="/images/logoimg.png" alt="logo over banner"></img>
              </div>
              <div className="text-fld">
                <h6>We welcome you to Nepal for</h6>
                <h5>RTI Half-Yearly Meeting 2024</h5>
                <p className="py-3">
                  28<sup>th</sup> Feb 2024- 3<sup>rd</sup> March 2024
                </p>
                <CountDown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
