import React from "react";

const Youtubesec = () => {
    return (
        <>
            <div className="section med-sec">
                <div className="container">
                    <div className="sub-section text-center">
                        <h5 className="title m-0">See how we do things differently at the HYM 2024</h5>
                        <p className="sub-title">Come and visit us for celebrations! Let us sit next to each other at table for the half year summoning.</p>
                    </div>
                         <iframe width="100%" height="550px" src="https://www.youtube.com/embed/Dt5nbbtQ8O4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
                         encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </div>
            </div>

        </>
    )
}

export default Youtubesec