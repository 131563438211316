import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Accomodationpage from "../Pages/Accomodationpage";
import Checkoutpage from "../Pages/Checkoutpage";
import Daytourpage from "../Pages/Daytourpage";
import Homepage from "../Pages/Homepage";
import Pretourpage from "../Pages/Pretourpage";
import Registrationpage from "../Pages/Registrationpage";
import Tourdescription from "../Pages/Tourdescriptionpage";
import Childrencorner from "../Pages/Childrencornerpage";
import Cartpage from "../Pages/Cartpage";
import Regdetailcard from "../Component/Reg_peg_cmp/Regdetailcard";
import Termscondation from "../Pages/Termscondation";
import Comingsoon from "../Component/comingsoon";
import PaymentError from "../Pages/Payment/Error";
import PaymentSuccess from "../Pages/Payment/Success";

const Allroutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />}></Route>

        <Route
          path="/accomodation/accomodationdescription/:id"
          element={<Accomodationpage />}
        ></Route>

        <Route path="/registration" element={<Registrationpage />}></Route>
        <Route
          path="/registration/registrationdetail/:id"
          element={<Regdetailcard />}
        ></Route>

        <Route path="/tour/pre-tour" element={<Pretourpage />}></Route>
        <Route
          path="/tour/pre-tour/pretourdescription/:id"
          element={<Tourdescription type="pre" />}
        ></Route>

        <Route path="/tour/day-tour" element={<Daytourpage />}></Route>
        <Route
          path="/tour/day-tour/daytourdescription/:id"
          element={<Tourdescription type="day" />}
        ></Route>

        <Route path="/tour/childrencorner" element={<Childrencorner />}></Route>

        <Route path="/cart" element={<Cartpage />}></Route>

        <Route path="/cart/checkout" element={<Checkoutpage />}></Route>

        {/* <Route path="/cart/checkout" element={<Comingsoon />}></Route> */}

        <Route path="termsandcondations" element={<Termscondation />}></Route>

        <Route path="/terms&condition" element={<Termscondation />}></Route>

        <Route path="/payment/error" element={<PaymentError />} />

        <Route path="/payment/success" element={<PaymentSuccess />} />
      </Routes>
    </>
  );
};

export default Allroutes;
