import React, { useEffect, useState } from "react";
import axios from "axios";
import Roomavailable from "../Component/Accomodation/Roomavailable";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { DetailLoader } from "../Loaders/DetailPageLoader";

const Accomodationpage = () => {
  const [loading, setLoading] = useState(true);
  const [accomodationdata, setAccomodationdata] = useState([]);
  const [roomImageURL, setRoomImageURL] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [imagelist, setImageList] = useState("");
  const params = useParams();
  console.log("paramsid", params);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}accommodation/get/${params.id}`,
    })
      .then((res) => {
        let response = res?.data?.data?.accommodation;
        setAccomodationdata(response);
        console.log("printttttttttttttt", res?.data?.data?.accommodation);
        setMainImage(res?.data?.data?.accommodation_main_image_base_url);
        setRoomImageURL(res?.data?.data?.room_images_base_url);
        setImageList(res?.data?.data?.accommodation_images_base_url);
        setLoading(false);
      })
      .catch((err) => {
        console.log("this is error", err);
        setLoading(false);
      });
  }, [params]);

  // console.log("accomodation data on state", accomodationdata);
  // console.log("room images", roomImageURL);
  // console.log("main img sec", mainImage)
  // console.log("list of images", imagelist)
  // console.log("here we print", accomodationdata?.accommodation_images)

  if (loading) {
    return (
      <div className="container my-5">
        <DetailLoader />
      </div>
    );
  }

  return (
    <>
      <div className="pagesection">
        <div className="container">
          <div className="sub-section">
            <h5 className="title m-0">{accomodationdata?.title}</h5>
            <p className="sub-title">{accomodationdata?.address}</p>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 htl-vew-pnt">
              <img
                src={mainImage + "/" + accomodationdata?.main_image}
                alt="Hotel view point"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="row">
                {accomodationdata?.accommodation_images?.map((data, key) => (
                  <>
                    <div className="col-lg-6 col-md-6 vew-pnt mb-4">
                      <img
                        src={`${imagelist}/${data?.image}`}
                        alt="Hotel view point"
                      ></img>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="description">
              <h6>{accomodationdata?.name}</h6>
              <p>{parse(accomodationdata?.description || "")}</p>
            </div>
            {/* <div className="amenities">
              <h6>Amenities</h6>
              <ul>
                {accomodationdata?.amenities?.map((data, key) => (
                  <>
                    <li>
                      <a href="">{data.title}</a>
                    </li>
                  </>
                ))}
              </ul>
            </div> */}
            <div className="room-dtl">
              <h6>Room Available</h6>
              <div className="row">
                {accomodationdata?.rooms?.map((get, key) => (
                  <>
                    <Roomavailable data={get} imageurl={roomImageURL} />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accomodationpage;
