import React from "react";
import { Link } from "react-router-dom";

function PaymentSuccess() {
  return (
    <div id="payment-success">
      {" "}
      <div className="container">
        <div className="success-message">
          <div className="success-icon">
            <i className="bi bi-check-circle-fill" />
          </div>
          <div className="success-title">Order Successful</div>
          <div className="success-text">
            Thank you for your purchase. Your order was successfully received.
          </div>
          <div className="back-button">
            <Link to="/" className="btn btn-success">
              <i className="bi bi-house-fill" /> Back to Homepage
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
