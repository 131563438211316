import Swal from "sweetalert2";

const toasterConfig = {
  toast: true,
  position: "bottom-left",
  timer: 3000,
  showConfirmButton: false,
  timerProgressBar: true,
};

const modalConfig = {
  timer: 3000,
  showConfirmButton: false,
  timerProgressBar: true,
};

export const fireErrorToaster = (message) => {
  Swal.fire({
    // title: title || "Error!",
    text: message,
    icon: "error",
    ...toasterConfig,
  });
};

export const fireSuccessToaster = (message) => {
  Swal.fire({
    // title: title || "Success!",
    text: message,
    icon: "success",
    ...toasterConfig,
  });
};

export const fireErrorModal = (message, title) => {
  Swal.fire({
    title: title || "Error!",
    html: `<h4> ${message} </h4>`,
    icon: "error",
    ...modalConfig,
  });
};

export const fireSuccessModal = (message, title) => {
  Swal.fire({
    title: title || "Success!",
    html: `<h4> ${message} </h4>`,
    icon: "success",
    ...modalConfig,
  });
};
